<template>
  <div>
    <Pane />

    <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>合同信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <a-select :disabled="true" placeholder="" show-search :filter-option="$selectFilterOption"
                style="width: 100%" v-decorator="[
                                    'operationDeptId',
                                    { rules: [{ required: true, message: '请选择！' }] },
                                  ]">
                <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.uniqueName
                                  }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办人</span>

              <a-button block style="text-align: left" class="disabled">
                <div v-if="selectedUser.name">
                  {{ selectedUser.name }}
                </div>
                <div v-else>&nbsp;</div>
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <a-input :disabled="true" v-decorator="[
                                'name',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同方</span>

              <a-button block style="margin-top: 4px; text-align: left" class="ellipsis disabled">
                {{ selectedCompany.name }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <a-cascader :disabled="true" placeholder="" v-decorator="[
                                'area',
                              ]" :options="areaList" :fieldNames="{
                      label: 'areaName',
                      value: 'key',
                      children: 'children',
                    }" />
              <div class="tip">请正确选择区域，具体分布详见表单下方说明</div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否有金额或费用比例">
              <a-radio-group :disabled="true" v-decorator="[
                                'isKjContractExpense',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="用章名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-checkbox-group :disabled="true" v-decorator="[
                                'sealType',
                                { rules: [{ required: true, message: '请选择！' }] },
                              ]">
                <a-checkbox v-for="item in sealTypeList" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="合同主要内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea :disabled="true" v-decorator="[
                                'remarks',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请输入！',
                                    },
                                  ],
                                },
                              ]" :auto-size="{ minRows: 3 }" />
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">附件</span>
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  <a :href="text.completePath" :download="$getFileName(text.completePath)" target="_blank">
                    {{ $getFileName(text.completePath) }}
                  </a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";

import { fetchDetail } from "@/api/framework-contract-register";
import { mapGetters, mapState } from "vuex";

import ExtraInfo from "./components/extra-info";

export default {
  mixins: [area, organization, watermark],

  components: {
    ExtraInfo,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedCompany: {}, // 选择的合同方

      fileList: [], // 附件列表

      saveLoading: false,
      id: null, // 重新提交时的 ID

      commentVisible: false, // 审批意见弹窗
      storageValue: {}, // 暂存的数据
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    sealTypeList() {
      return this.findDataDict("sealType");
    },

    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        if (res) {
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          // 合同方
          this.selectedCompany.id = res.partyAid;
          this.selectedCompany.name = res.partyAname;

          const area = [];
          if (res.areaId) {
            area.push(res.areaId);
          }
          if (res.subAreaId) {
            area.push(res.subAreaId);
          }
          if (res.l3AreaId) {
            area.push(res.l3AreaId);
          }

          this.form.setFieldsValue({
            // 经办
            operationDeptId: res.operationDeptId,

            // 合同
            name: res.name,
            remarks: res.remarks,

            // 用章名称
            sealType: res.sealType?.split(","),

            // 所属区域
            area,

            // 是否有金额或费用比例
            isKjContractExpense: res.isKjContractExpense,
          });

          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    }

    if (!id) {
      if (!this.selectedUser.name) {
        this.selectedUser = {
          userId: this.user.uuid,
          name: this.user.userName,
        };

        this.form.setFieldsValue({
          operationDeptId: this.department.deptId,
        });
      }
    }
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
