var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("../../assets/contract-register/icon-info.png"),"alt":""}}),_c('span',[_vm._v("合同信息")])]),_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"经办部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                  'operationDeptId',
                                  { rules: [{ required: true, message: '请选择！' }] },
                                ]),expression:"[\n                                  'operationDeptId',\n                                  { rules: [{ required: true, message: '请选择！' }] },\n                                ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":"","show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("经办人")]),_c('a-button',{staticClass:"disabled",staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedUser.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'name',
                              { rules: [{ required: true, message: '请输入！' }] },
                            ]),expression:"[\n                              'name',\n                              { rules: [{ required: true, message: '请输入！' }] },\n                            ]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("合同方")]),_c('a-button',{staticClass:"ellipsis disabled",staticStyle:{"margin-top":"4px","text-align":"left"},attrs:{"block":""}},[_vm._v(" "+_vm._s(_vm.selectedCompany.name)+" ")])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属区域"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'area',
                            ]),expression:"[\n                              'area',\n                            ]"}],attrs:{"disabled":true,"placeholder":"","options":_vm.areaList,"fieldNames":{
                    label: 'areaName',
                    value: 'key',
                    children: 'children',
                  }}}),_c('div',{staticClass:"tip"},[_vm._v("请正确选择区域，具体分布详见表单下方说明")])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否有金额或费用比例"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'isKjContractExpense',
                              { rules: [{ required: true, message: '请选择！' }] },
                            ]),expression:"[\n                              'isKjContractExpense',\n                              { rules: [{ required: true, message: '请选择！' }] },\n                            ]"}],attrs:{"disabled":true}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":0}},[_vm._v("否")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"用章名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'sealType',
                              { rules: [{ required: true, message: '请选择！' }] },
                            ]),expression:"[\n                              'sealType',\n                              { rules: [{ required: true, message: '请选择！' }] },\n                            ]"}],attrs:{"disabled":true}},_vm._l((_vm.sealTypeList),function(item){return _c('a-checkbox',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同主要内容","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'remarks',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入！',
                                  },
                                ],
                              },
                            ]),expression:"[\n                              'remarks',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请输入！',\n                                  },\n                                ],\n                              },\n                            ]"}],attrs:{"disabled":true,"auto-size":{ minRows: 3 }}})],1)],1)],1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("../../assets/contract-register/icon-file.png"),"alt":""}}),_c('span',[_vm._v("附件")])]),_c('Padding',{attrs:{"size":"large"}}),_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{staticStyle:{"margin-top":"4px"}},[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.fileList,"pagination":false}},[_c('a-table-column',{attrs:{"title":"附件名称"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":text.completePath,"download":_vm.$getFileName(text.completePath),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$getFileName(text.completePath))+" ")])]}}])})],1)],1)]),_c('Padding',{attrs:{"size":"large"}})],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)]),_c('ExtraInfo')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }